<script lang="ts" setup>
import { useGlobalStore, useFormStore } from '@/stores'

const route = useRoute()
const globalStore = useGlobalStore()
const formStore = useFormStore()
const fastRepairCalendar = computed(() => formStore.fastRepairCalendar)
const dialogData = computed(() => globalStore.dialogData)

const handleClose = () => {
    if (dialogData.value.needLogin) {
        const redirectUrl = import.meta.env.VITE_SSO_URL.replace('{:redirect_url}', import.meta.env.VITE_DOMAIN + encodeURIComponent(route.fullPath)).replace('{:banner_id}', dialogData.value.banner)

        return navigateTo(redirectUrl, { external: true })
    }

    globalStore.setDialogVisible({
        visible: false,
    } as any)
}

const handleCalendarEvent = () => {
    createGoogleCalendarEvent(fastRepairCalendar.value.title, fastRepairCalendar.value.content, fastRepairCalendar.value.datetime, fastRepairCalendar.value.address)
    globalStore.setDialogVisible({
        visible: false,
    } as any)
}
</script>

<template>
    <el-dialog v-model="dialogData.visible" :title="dialogData.title" :width="dialogResize" :before-close="() => { }"
        v-if="dialogData.eventType === 'tips'">
        <span>{{ dialogData.message }}</span>
        <span v-html="dialogData.html"></span>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handleClose">{{ dialogData.confirmButtonText }}</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="dialogData.visible" :title="dialogData.title" :width="dialogResize"
        v-if="dialogData.eventType === 'calendar'">
        <span>{{ dialogData.message }}</span>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handleClose">不需要</el-button>
                <el-button type="primary" @click="handleCalendarEvent()">
                    需要
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>


<style scoped>
.dialog-footer button:first-child {
    margin-right: 10px;
}
</style>